<template>
    <div class="wrapper">
        <div id="content">
            <br>
            <h1>PRIVACY NOTICE</h1>
            <div><br></div>
            <div><strong>Last updated December 22, 2020</strong></div>
            <div><br></div>
            <div><br></div>
            <div><br></div>
            <div>Thank you for choosing to be part of our community at strukteon.net ("company", "we", "us", "our"). We are
                committed to protecting your personal information and your right to privacy. If you have any questions or
                concerns about our policy, or our practices with regards to your personal information, please contact us at
                nils@strukteon.net.
            </div>
            <div><br></div>
            <div>When you visit our website <a decorated href="https://strukteon.net">https://strukteon.net</a>, and use our services, you
                trust us with your personal information. We take your privacy very seriously. In this privacy notice, we
                describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect,
                how we use it and what rights you have in relation to it. We hope you take some time to read through it
                carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please
                discontinue use of our Sites and our services.
            </div>
            <div><br></div>
            <div>This privacy policy applies to all information collected through our website (such as <a decorated
                                                                                                          href="https://strukteon.net">https://strukteon.net</a>), and/or any related services, sales, marketing or
                events (we refer to them collectively in this privacy policy as the "<strong>Sites</strong>").
            </div>
            <div><br></div>
            <div><strong>Please read this privacy <strong>policy carefully as it will help you make informed decisions about
                sharing your personal information with us</strong>.</strong>
            </div>
            <div><br></div>
            <h3>TABLE OF CONTENTS</h3>
            <div><br></div>
            <div><a decorated href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
            </div>
            <div><a decorated href="#infouse">2. HOW DO WE USE YOUR INFORMATION?</a>
            </div>
            <div><a decorated href="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
            </div>
            <div><a decorated
                    href="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
            </div>
            <div><a decorated
                    href="#cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
            </div>
            <div><a decorated href="#sociallogins">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
            </div>
            <div><a decorated href="#3pwebsites">7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a>
            </div>
            <div><a decorated href="#inforetain">8. HOW LONG DO WE KEEP YOUR INFORMATION?</a></div>
            <div><a decorated href="#infosafe">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </div>
            <div><a decorated
                    href="#infominors">10. DO WE COLLECT INFORMATION FROM MINORS?</a>
            </div>
            <div><a decorated href="#privacyrights">11. WHAT ARE YOUR PRIVACY RIGHTS?</a></div>
            <div><a decorated href="#DNT">12. CONTROLS FOR DO-NOT-TRACK FEATURES</a></div>
            <div><a decorated
                    href="#caresidents">13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
            </div>
            <div><a decorated href="#policyupdates">14. DO WE MAKE UPDATES TO THIS NOTICE?</a></div>
            <div><a decorated href="#contact">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></div>
            <div><br></div>
            <div id="infocollect"><strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
            </div>
            <div><strong><u><br></u></strong><strong>Personal information you disclose to us</strong>
            </div>
            <div>
                <div><br></div>
                <div>
                    <strong><em>In Short: </em></strong><strong><em> </em></strong><em>We collect personal information that you
                    provide to us <em>such as name, address, contact information, passwords and security data, payment
                        information, and social media login data</em></em>.
                </div>
            </div>
            <div><br></div>
            <div>We collect personal information that you voluntarily provide to us when registering at the Sites expressing an
                interest in obtaining information about us or our products and services, when participating in activities on the
                Sites (such as posting messages in our online forums or entering competitions, contests or giveaways) or
                otherwise contacting us.
            </div>
            <div><br></div>
            <div>The personal information that we collect depends on the context of your interactions with us and the Sites, the
                choices you make and the products and features you use. The personal information we collect may include the
                following:
            </div>
            <div><br></div>
            <div><strong>Name and Contact Data.</strong> We collect your first and last name, email address, postal address,
                phone number, and other similar contact data.
            </div>
            <div><br></div>
            <div><strong>Credentials.</strong> We collect passwords, password hints, and similar security information used for
                authentication and account access.
            </div>
            <div><br></div>
            <div><strong>Payment Data.</strong> We collect data necessary to process your payment if you make purchases, such as
                your payment instrument number (such as a credit card number), and the security code associated with your
                payment instrument. All payment data is stored by our payment processor and you should review its privacy
                policies and contact the payment processor directly to respond to your questions.
            </div>
            <div><br></div>
            <div>
                <strong>Social Media Login Data. </strong>We provide you with the option to register using social media account
                details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we
                will collect the Information described in the section called "<a decorated
                                                                                 href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>" below.
            </div>
            <div><br></div>
            <div>All personal information that you provide to us must be true, complete and accurate, and you must notify us of
                any changes to such personal information.
            </div>
            <div><strong><u><br></u></strong><strong>Information automatically collected</strong>
            </div>
            <div>
                <div><br></div>
                <div>
                    <strong><em>In Short: </em></strong><strong><em> </em></strong><em>Some information — such as IP address
                    and/or browser and device characteristics — is collected automatically when you visit our
                    <em>Sites</em>.</em>
                </div>
            </div>
            <div><br></div>
            <div>We automatically collect certain information when you visit, use or navigate the Sites. This information does
                not reveal your specific identity (like your name or contact information) but may include device and usage
                information, such as your IP address, browser and device characteristics, operating system, language
                preferences, referring URLs, device name, country, location, information about how and when you use our Sites
                and other technical information. This information is primarily needed to maintain the security and operation of
                our Sites, and for our internal analytics and reporting purposes.
            </div>
            <div><br></div>
            <div>
                Like many businesses, we also collect information through cookies and similar technologies.
                <div>
                    <strong><u><br></u></strong><strong>Information collected from other sources</strong>
                </div>
                <div>
                    <div><br></div>
                    <div><strong><em>In Short: </em></strong><em>We may collect limited data from public databases, marketing
                        partners, <em>social media platforms, </em>and other outside sources.</em>
                    </div>
                </div>
                <div><br></div>
                <div>We may obtain information about you from other sources, such as public databases, joint marketing partners,
                    social media platforms (such as Facebook), as well as from other third parties. Examples of the information
                    we receive from other sources include: social media profile information (your name, gender, birthday, email,
                    current city, state and country, user identification numbers for your contacts, profile picture URL and any
                    other information that you choose to make public); marketing leads and search results and links, including
                    paid listings (such as sponsored links).
                </div>
                <div><br></div>
                <div id="infouse"><strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
                </div>
                <div>
                    <div><br></div>
                    <div><strong><em>In Short: </em></strong><em>We process your information for purposes based on legitimate
                        business interests, the fulfillment of our contract with you, compliance with our legal obligations,
                        and/or your consent.</em>
                    </div>
                </div>
                <div><br></div>
                <div>We use personal information collected via our Sites for a variety of business purposes described below. We
                    process your personal information for these purposes in reliance on our legitimate business interests
                    ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your
                    consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the
                    specific processing grounds we rely on next to each purpose listed below.
                </div>
                <div><br></div>
                <div>We use the information we collect or receive:
                </div>
                <ul>
                    <li><strong>To facilitate account creation and logon process.</strong> If you choose to link your account
                        with us to a third party account (such as your Google or Facebook account), we use the information you
                        allowed us to collect from those third parties to facilitate account creation and logon process. See the
                        section below headed "<a decorated
                                                 href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>" for further information.
                    </li>
                </ul>
                <div></div>
                <ul>
                    <li><strong>To send you marketing and promotional communications.</strong> We and/or our third party
                        marketing partners may use the personal information you send to us for our marketing purposes, if this
                        is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time
                        (see the "<a decorated
                                     href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS</a>" below).
                    </li>
                </ul>
                <div>
                </div>
                <ul>
                    <li><strong>To send administrative information to you. </strong>We may use your personal information to send
                        you product, service and new feature information and/or information about changes to our terms,
                        conditions, and policies.
                    </li>
                </ul>
                <div>
                </div>
                <ul>
                    <li><strong>Fulfill and manage your orders. </strong>We may use your information to fulfill and manage your
                        orders, payments, returns, and exchanges made through the Sites.
                    </li>
                </ul>
                <div>
                    <div></div>
                    <ul>
                        <li><strong>Deliver targeted advertising to you.</strong> We may use your information to develop and
                            display content and advertising (and work with third parties who do so) tailored to your interests
                            and/or location and to measure its effectiveness.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Administer prize draws and competitions.</strong> We may use your information to administer
                            prize draws and competitions when you elect to participate in our competitions.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Request Feedback. </strong>We may use your information to request feedback and to contact
                            you about your use of our Sites.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>To protect our Sites. </strong>We may use your information as part of our efforts to keep
                            our Sites safe and secure (for example, for fraud monitoring and prevention).
                        </li>
                    </ul>
                    <p></p>
                    <ul>
                        <li><strong>To enable user-to-user communications. </strong>We may use your information in order to
                            enable user-to-user communications with each user's consent.
                        </li>
                    </ul>
                    <p></p>
                    <ul>
                        <li><strong>To enforce our terms, conditions and policies.</strong></li>
                    </ul>
                    <p></p>
                    <ul>
                        <li><strong>To respond to legal requests and prevent harm.</strong> If we receive a subpoena or other
                            legal request, we may need to inspect the data we hold to determine how to respond.
                        </li>
                    </ul>
                    <p></p>
                    <ul>
                        <li><strong>For other Business Purposes.</strong> We may use your information for other Business
                            Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our
                            promotional campaigns and to evaluate and improve our Sites, products, services, marketing and your
                            experience.
                        </li>
                    </ul>
                    <div></div>
                    <div><br></div>
                    <div
                        id="infoshare"><strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong></div>
                    <div><br></div>
                    <div><strong><em>In Short:</em></strong><em> We only share information with your consent, to comply with
                        laws, to provide you with services, to protect your rights, or to fulfill business obligations.</em>
                    </div>
                    <div><br></div>
                    <div>We may process or share your data that we hold based on the following legal basis:</div>
                    <ul>
                        <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your
                            personal information for a specific purpose.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to
                            achieve our legitimate business interests.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may
                            process your personal information to fulfill the terms of our contract.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Legal Obligations:</strong> We may disclose your information where we are legally required
                            to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court
                            order, or legal process, such as in response to a court order or a subpoena (including in response
                            to public authorities to meet national security or law enforcement requirements).
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary
                            to investigate, prevent, or take action regarding potential violations of our policies, suspected
                            fraud, situations involving potential threats to the safety of any person and illegal activities, or
                            as evidence in litigation in which we are involved.
                        </li>
                    </ul>
                    <div>More specifically, we may need to process your data or share your personal information in the following
                        situations:
                    </div>
                    <ul>
                        <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with,
                            or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                            portion of our business to another company.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your
                            data with third party vendors, service providers, contractors or agents who perform services for us
                            or on our behalf and require access to such information to do that work. Examples include: payment
                            processing, data analysis, email delivery, hosting services, customer service and marketing efforts.
                            We may allow selected third parties to use tracking technology on the Sites, which will enable them
                            to collect data about how you interact with the Sites over time. This information may be used to,
                            among other things, analyze and track data, determine the popularity of certain content and better
                            understand online activity. Unless described in this Policy, we do not share, sell, rent or trade
                            any of your information with third parties for their promotional purposes.
                        </li>
                    </ul>
                    <div></div>
                    <ul>
                        <li><strong>Third-Party Advertisers.</strong> We may use third-party advertising companies to serve ads
                            when you visit the Sites. These companies may use information about your visits to our Website(s)
                            and other websites that are contained in web cookies and other tracking technologies in order to
                            provide advertisements about goods and services of interest to you.
                        </li>
                    </ul>
                    <div>
                        <div>
                            <div></div>
                            <ul>
                                <li><strong>Other Users. </strong>When you share personal information (for example, by posting
                                    comments, contributions or other content to the Sites) or otherwise interact with public
                                    areas of the Sites, such personal information may be viewed by all users and may be publicly
                                    distributed outside the Sites in perpetuity. If you interact with other users of our Sites
                                    and register through a social network (such as Facebook), your contacts on the social
                                    network will see your name, profile photo, and descriptions of your activity. Similarly,
                                    other users will be able to view descriptions of your activity, communicate with you within
                                    our Sites, and view your profile.
                                </li>
                            </ul>
                            <div>
                                <div></div>
                                <div><br></div>
                                <div
                                    id="whoshare"><strong>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short:</em></strong><em> We only share information with the following third
                                    parties.</em></div>
                                <div><br></div>
                                <div>We only share and disclose your information with the following third parties. We have
                                    categorised each party so that you may be easily understand the purpose of our data
                                    collection and processing practices. If we have processed your data based on your consent
                                    and you wish to revoke your consent, please contact us.
                                </div>
                                <ul>
                                    <li><strong>Content Optimisation</strong></li>
                                </ul>
                                <div>YouTube video embed</div>
                                <ul>
                                    <li><strong>Functionality and Infrastructure Optimisation</strong></li>
                                </ul>
                                <div>Firebase Realtime Database</div>
                                <ul>
                                    <li><strong>Invoice and Billing</strong></li>
                                </ul>
                                <div>PayPal</div>
                                <ul>
                                    <li><strong>User Account Registration and Authentication</strong></li>
                                </ul>
                                <div>Google OAuth 2.0</div>
                                <ul>
                                    <li><strong>Web and Mobile Analytics</strong></li>
                                </ul>
                                <div>Google Analytics</div>
                                <div></div>
                                <div><br></div>
                                <div
                                    id="cookies"><strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
                                </div>
                                <div><br></div>
                                <div><strong><em>In Short: </em></strong><em> We may use cookies and other tracking technologies
                                    to collect and store your information.</em></div>
                                <div><br></div>
                                <div>We may use cookies and similar tracking technologies (like web beacons and pixels) to
                                    access or store information. Specific information about how we use such technologies and how
                                    you can refuse certain cookies is set out in our Cookie Policy.
                                </div>
                                <div><br></div>
                                <div
                                    id="sociallogins"><strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short: </em></strong><em>If you choose to register or log in to our website
                                    using a social media account, we may have access to certain information about you.</em>
                                </div>
                                <div><br></div>
                                <div>Our Sites offers you the ability to register and login using your third party social media
                                    account details (like your Facebook or Twitter logins). Where you choose to do this, we will
                                    receive certain profile information about you from your social media provider. The profile
                                    Information we receive may vary depending on the social media provider concerned, but will
                                    often include your name, e-mail address, friends list, profile picture as well as other
                                    information you choose to make public.
                                </div>
                                <div><br></div>
                                <div>We will use the information we receive only for the purposes that are described in this
                                    privacy policy or that are otherwise made clear to you on the Sites. Please note that we do
                                    not control, and are not responsible for, other uses of your personal information by your
                                    third party social media provider. We recommend that you review their privacy policy to
                                    understand how they collect, use and share your personal information, and how you can set
                                    your privacy preferences on their sites and apps.
                                </div>
                                <div></div>
                                <div><strong>7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short: </em></strong><em> We are not responsible for the safety of any
                                    information that you share with third-party providers who advertise, but are not affiliated
                                    with, our websites.</em></div>
                                <div><br></div>
                                <div>The Sites may contain advertisements from third parties that are not affiliated with us and
                                    which may link to other websites, online services or mobile applications. We cannot
                                    guarantee the safety and privacy of data you provide to any third parties. Any data
                                    collected by third parties is not covered by this privacy policy. We are not responsible for
                                    the content or privacy and security practices and policies of any third parties, including
                                    other websites, services or applications that may be linked to or from the Sites. You should
                                    review the policies of such third parties and contact them directly to respond to your
                                    questions.
                                </div>
                                <div><br></div>
                                <div
                                    id="inforetain"><strong>8. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short: </em></strong><em> We keep your information for as long as necessary
                                    to fulfill the purposes outlined in this privacy policy unless otherwise required by
                                    law.</em></div>
                                <div><br></div>
                                <div>We will only keep your personal information for as long as it is necessary for the purposes
                                    set out in this privacy policy, unless a longer retention period is required or permitted by
                                    law (such as tax, accounting or other legal requirements). No purpose in this policy will
                                    require us keeping your personal information for longer than 2 years past the termination of
                                    the user's account.
                                </div>
                                <div><br></div>
                                <div>When we have no ongoing legitimate business need to process your personal information, we
                                    will either delete or anonymize it, or, if this is not possible (for example, because your
                                    personal information has been stored in backup archives), then we will securely store your
                                    personal information and isolate it from any further processing until deletion is possible.
                                </div>
                                <div><br></div>
                                <div
                                    id="infosafe"><strong>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short: </em></strong><em> We aim to protect your personal information
                                    through a system of organisational and technical security measures.</em></div>
                                <div><br></div>
                                <div>We have implemented appropriate technical and organisational security measures designed to
                                    protect the security of any personal information we process. However, please also remember
                                    that we cannot guarantee that the internet itself is 100% secure. Although we will do our
                                    best to protect your personal information, transmission of personal information to and from
                                    our Sites is at your own risk. You should only access the services within a secure
                                    environment.
                                </div>
                                <div><br></div>
                                <div
                                    id="infominors"><strong>10. DO WE COLLECT INFORMATION FROM MINORS?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short:</em></strong><em> We do not knowingly collect data from or market to
                                    children under 18 years of age.</em></div>
                                <div><br></div>
                                <div>We do not knowingly solicit data from or market to children under 18 years of age. By using
                                    the Sites, you represent that you are at least 18 or that you are the parent or guardian of
                                    such a minor and consent to such minor dependent’s use of the Sites. If we learn that
                                    personal information from users less than 18 years of age has been collected, we will
                                    deactivate the account and take reasonable measures to promptly delete such data from our
                                    records. If you become aware of any data we may have collected from children under age 18,
                                    please contact us at nils@strukteon.net.
                                </div>
                                <div><br></div>
                                <div
                                    id="privacyrights"><strong>11. WHAT ARE YOUR PRIVACY RIGHTS?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short:</em></strong><em> In some regions, such as the European Economic
                                    Area, you have rights that allow you greater access to and control over your personal
                                    information. You may review, change, or terminate your account at any time.</em></div>
                                <div><br></div>
                                <div>In some regions (like the European Economic Area), you have certain rights under applicable
                                    data protection laws. These may include the right (i) to request access and obtain a copy of
                                    your personal information, (ii) to request rectification or erasure; (iii) to restrict the
                                    processing of your personal information; and (iv) if applicable, to data portability. In
                                    certain circumstances, you may also have the right to object to the processing of your
                                    personal information. To make such a request, please use the <a decorated
                                                                                                    href="#contact">contact details</a> provided below. We will consider and act upon
                                    any request in accordance with applicable data protection laws.
                                </div>
                                <div><br></div>
                                <div>If we are relying on your consent to process your personal information, you have the right
                                    to withdraw your consent at any time. Please note however that this will not affect the
                                    lawfulness of the processing before its withdrawal.
                                </div>
                                <div></div>
                                <div>If you are a resident in the European Economic Area and you believe we are unlawfully
                                    processing your personal information, you also have the right to complain to your local data
                                    protection supervisory authority. You can find their contact details here: <a decorated
                                                                                                                  href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
                                </div>
                                <div><strong><u><br></u></strong><strong>Account Information</strong></div>
                                <div>
                                    <div><br></div>
                                    <div>If you would at any time like to review or change the information in your account or
                                        terminate your account, you can:
                                    </div>
                                    <ul>
                                        <li>Log into your account settings and update your user account.</li>
                                    </ul>
                                    <div></div>
                                    <ul>
                                        <li>Contact us using the contact information provided.</li>
                                    </ul>
                                    <div></div>
                                    <div>Upon your request to terminate your account, we will deactivate or delete your account
                                        and information from our active databases. However, some information my be retained in
                                        our files to prevent fraud, troubleshoot problems, assist with any investigations,
                                        enforce our Terms of Use and/or comply with legal requirements.
                                    </div>
                                </div>
                                <div><br></div>
                                <div><strong><u>Cookies and similar technologies:</u></strong> Most Web browsers are set to
                                    accept cookies by default. If you prefer, you can usually choose to set your browser to
                                    remove cookies and to reject cookies. If you choose to remove cookies or reject cookies,
                                    this could affect certain features or services of our Sites. To opt-out of interest-based
                                    advertising by advertisers on our Sites visit <a decorated
                                                                                     href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
                                </div>
                                <div><br></div>
                                <div><strong><u>Opting out of email marketing:</u></strong> You can unsubscribe from our
                                    marketing email list at any time by clicking on the unsubscribe link in the emails that we
                                    send or by contacting us using the details provided below. You will then be removed from the
                                    marketing email list — however, we will still need to send you service-related emails that
                                    are necessary for the administration and use of your account. To otherwise opt-out, you may:
                                </div>
                                <ul>
                                    <li>Note your preferences when you register an account with the site.</li>
                                </ul>
                                <div></div>
                                <ul>
                                    <li>Contact us using the contact information provided.</li>
                                </ul>
                                <div></div>
                                <ul>
                                    <li>Access your account settings and update preferences.</li>
                                </ul>
                                <div></div>
                                <div><br></div>
                                <div
                                    id="DNT"><strong>12. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></div>
                                <div><br></div>
                                <div>Most web browsers and some mobile operating systems and mobile applications include a
                                    Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference
                                    not to have data about your online browsing activities monitored and collected. No uniform
                                    technology standard for recognizing and implementing DNT signals has been finalized. As
                                    such, we do not currently respond to DNT browser signals or any other mechanism that
                                    automatically communicates your choice not to be tracked online. If a standard for online
                                    tracking is adopted that we must follow in the future, we will inform you about that
                                    practice in a revised version of this Privacy Policy.
                                </div>
                                <div><br></div>
                                <div
                                    id="caresidents"><strong>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                                    RIGHTS?</strong></div>
                                <div><br></div>
                                <div><strong><em>In Short: </em></strong><em> Yes, if you are a resident of California, you are
                                    granted specific rights regarding access to your personal information.</em></div>
                                <div><br></div>
                                <div>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
                                    users who are California residents to request and obtain from us, once a year and free of
                                    charge, information about categories of personal information (if any) we disclosed to third
                                    parties for direct marketing purposes and the names and addresses of all third parties with
                                    which we shared personal information in the immediately preceding calendar year. If you are
                                    a California resident and would like to make such a request, please submit your request in
                                    writing to us using the contact information provided below.
                                </div>
                                <div><br></div>
                                <div>If you are under 18 years of age, reside in California, and have a registered account with
                                    the Sites, you have the right to request removal of unwanted data that you publicly post on
                                    the Sites. To request removal of such data, please contact us using the contact information
                                    provided below, and include the email address associated with your account and a statement
                                    that you reside in California. We will make sure the data is not publicly displayed on the
                                    Sites, but please be aware that the data may not be completely or comprehensively removed
                                    from all our systems.
                                </div>
                                <div><br></div>
                                <div
                                    id="policyupdates"><strong>14. DO WE MAKE UPDATES TO THIS POLICY?</strong></div>
                                <div><em><br></em></div>
                                <div><em><strong>In Short: </strong> Yes, we will update this policy as necessary to stay
                                    compliant with relevant laws.</em></div>
                                <div><br></div>
                                <div>We may update this privacy policy from time to time. The updated version will be indicated
                                    by an updated "Revised" date and the updated version will be effective as soon as it is
                                    accessible. If we make material changes to this privacy policy, we may notify you either by
                                    prominently posting a notice of such changes or by directly sending you a notification. We
                                    encourage you to review this privacy policy frequently to be informed of how we are
                                    protecting your information.
                                </div>
                                <div><br></div>
                                <div
                                    id="contact"><strong>15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</strong></div>
                                <div><br></div>
                                <div>If you have questions or comments about this policy, you may email us at nils@strukteon.net.</div>

                                <div><br></div>
                                <div
                                    id="request"><strong>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                                    YOU?</strong></div>
                                <div><br></div>
                                <div>Based on the applicable laws of your country, you may have the right to request access to
                                    the personal information we collect from you, change that information, or delete it in some
                                    circumstances. To request to review, update, or delete your personal information, please
                                    visit: mailto:nils@strukteon.net. We will respond to your request within 30 days.
                                </div>
                            </div>
                            <br>
                            <div>This privacy policy was created using <a decorated
                                                                          href="https://termly.io/products/privacy-policy-generator/?ftseo">Termly’s Privacy Policy
                                Generator</a>.
                            </div>

                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrivacyPolicy",
    metaInfo: {
        title: 'Privacy Policy',
        meta: [
            {property: 'og:title', content: 'Privacy Policy'},
            {property: 'og:site_name', content: 'strukteon.net'},
            {property: 'og:type', content: 'website'}
        ]
    }
}
</script>

<style scoped>
.wrapper {
    width: 100%;
    background-attachment: fixed;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("~@/assets/images/fog.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

#content {
    width: min(80em, 80%);
}

div {
    font-size: 15pt;
}
</style>